@import "/src/style/scss/colors.scss";
@import "/src/style/scss/mixin/mixin";

.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  align-items: flex-start;
  padding-top: 35px;
  position: relative;
  padding-bottom: 35px;


  &.hide {
    .card {
      @include m(maxXL) {
        &:nth-of-type(n + 3) {
          display: none;
        }
      }

      @include m(xl) {
        &:nth-of-type(n + 4) {
          display: none;
        }
      }

      @include m(1400) {
        &:nth-of-type(n + 4) {
          display: flex;
        }

        &:nth-of-type(n + 5) {
          display: none;
        }
      }

      @include m(1676) {

        &:nth-of-type(n + 5) {
          display: flex;
        }

        &:nth-of-type(n + 6) {
          display: none;
        }
      }


      @include m(1946) {
        &:nth-of-type(n + 6) {
          display: flex;
        }

        &:nth-of-type(n + 7) {
          display: none;
        }
      }
    }
  }
}

.card {
  border-radius: 8px;
  border: 1px solid rgba(14, 31, 65, 0.03);
  background: #FFF;
  box-shadow: 0px 8px 15px 0px rgba(40, 52, 101, 0.08);
  text-decoration: none !important;
  display: flex;
  height: 100%;
  flex-direction: column;

  &__header {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    gap: 5px;

    &_ctrl {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $black;
    word-break: break-word;
  }

  &__grow {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
    color: $success;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
    line-height: 24px;

    &_down {
      color: $error;
    }
  }

  &__body {
    padding: 15px;
    text-align: center;
    margin-top: auto;

    img {
      width: 100%;
    }
  }
}