@import "/src/style/scss/colors.scss";

.wrapAction{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  height: auto;
}

.nextBtn{
  margin-left: auto !important;
}
.prevBtnIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  margin-left: -10px;
  margin-right: -10px;
}