@import "style/scss/colors.scss";

.btn{
    &_edit{
        padding: 10px !important;
    }
}


.block_name{
    display: flex;
    align-items: center;
    gap: 10px;

    &__controls{
      display: flex;
      align-self: flex-start;
      align-items: center;
      gap: 10px;
      height: 50px;

    }
}

.input_name{
    min-width: 400px;
}

.box{
    margin-top: 20px;
    border: 1px solid $athens-gray-light;
    box-shadow: 0px 8px 20px 0px rgba(40, 52, 101, 0.1);
    padding: 30px;
    border-radius: 12px;
}

.checkbox{
  &__title{
    line-height: 32px;
  }
}


.btn_edit_keyWord{
  padding: 5px;
  margin-left: 10px;
}

.block{
  &:not(:last-of-type){
    border-bottom: 1px solid  $athens-gray;
    padding-bottom: 20px;
  }
  &:not(:first-of-type){
    padding-top: 20px;
  }
}

.newProspectItem{
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  padding: 4px 4px 4px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px dashed #CFD3DE;
  margin-top: 6px;
  margin-bottom: 6px;

  &__value{
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    font-weight: 500;
  }
}

.footer{
  margin-top: 20px;
}