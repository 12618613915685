// interface
$cinder: #15151F;
$pickled-bluewood: #38465D;
$lynch: #64738C;
$manatee: #939BAB;
$heather: #B3BECB;
$mischka: #CFD3DE;
$athens-gray: #E8EAEF;
$athens-gray-light: #F7F8FA;

// signum
$orange: #EB7D68;
$signum-gradient: linear-gradient(98.22deg, #F3B078 0%, #EB7D68 100%);
$hover-gradient: linear-gradient(98.22deg, #FFBC84 0%, #F2826D 100%);
$active-gradient: linear-gradient(98.22deg, #EEA567 0%, #E66F59 100%);
$disabled-gradient: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
    linear-gradient(98.22deg, #F3B078 0%, #EB7D68 100%);

// monochromatic
$oxford-blue: #323B4F;
$white: #fff;
$overlay: rgba(5, 6, 10, 0.3);

// illustrations
$indigo: #5F74BE;
$sail: #A5CBFA;
$pattens-blue: #DCEEFF;

//notification
$error: #EF5E39;
$error-hover: #DD4B26;
$attention: #FFA630;
$success: #34BD41;

//text
$black: #3E4555;
$dark-gray: #56637B;
$gray: #9298A8;
$text-gray: #B9B9BE;
$dark: #1B1E3F;
$text-error: #EC0910;

$orange-btn-shadow: 0 6px 12px rgba(235, 125, 104, 0.3);