@import "/src/style/scss/colors.scss";
@import "/src/style/scss/variable.scss";

html {
  .root {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    min-width: unset;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    transition: background-color $transition-normal, background-image $transition-normal;
    color: $black;
    box-shadow: unset !important;
    background-image: none;
    background-color: unset;
    text-transform: none;
    position: relative;
    transition: none;
    font-family: Rubik, sans-serif;
    line-height: 1.5715;
    letter-spacing: 0.02857em;

    &:disabled {
      cursor: not-allowed;
      pointer-events: auto;
    }
  }

  .primary {
    background-image: $signum-gradient;
    background-size: 100%;
    background-position: center;
    color: $white;
    box-shadow: $orange-btn-shadow !important;

    &:hover {
      background-image: $hover-gradient;
    }

    &:active {
      background-image: $active-gradient;
    }

    &:disabled {
      background-image: $disabled-gradient !important;
      color: $white !important;
    }
  }

  .secondary {
    color: $black;
    border: 1px solid $mischka;
    background-color: $white;
    background-image: none;
    box-shadow: none;

    &:hover {
      border: 1px solid $heather;
    }

    &:active {
      color: $white;
      background-color: $cinder;
      border-color: $cinder;
    }

    &:disabled {
      color: $gray !important;
      background-color: $athens-gray !important;
      border-color: $athens-gray !important;
    }
  }

  .secondaryBordered {
    color: $black;
    border: 1px solid $orange;
    background-color: $white;
    background-image: none;
    box-shadow: none;

    &:active {
      color: $white;
      background-color: $cinder;
    }

    &:disabled {
      color: $gray !important;
      background-color: $athens-gray !important;
      border-color: $athens-gray !important;
    }
  }

  .borderLess {
    color: $black;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: none !important;

    &:hover {
      background-color: $athens-gray-light;
      border-color: $athens-gray-light;
    }

    &:active {
      background-color: $athens-gray;
      border-color: $athens-gray;
    }

    &:disabled {
      color: $gray;
      background-color: $athens-gray-light;
      border-color: $athens-gray-light;
    }
  }

  .text {
    color: $black;
    padding: 0px !important;
    height: auto;
    line-height: 1.2;
    border: none;
    background: transparent !important;
    background-image: none;
    cursor: pointer;

    &_orange {
      color: $orange;
    }


    &:active {
      opacity: .7;
    }

    &:hover {
      opacity: .8;
    }

    &:disabled {
      color: $gray !important;
      opacity: 1 !important;
    }
  }


  .blue {
    background-color: $indigo;
    color: #fff;

    &:hover {
      background: darken($color: $indigo, $amount: 15%);
    }

    &:active {
      background: darken($color: $indigo, $amount: 30%);
    }


    &:disabled {
      color: $white !important;
      background-color: #7f8fcb !important;
    }
  }


  .danger {
    color: $error;
    border: 1px solid $error;
    background-color: $white;
    background-image: none;
    box-shadow: none;

    &:hover {
      color: $error-hover;
      border-color: $error-hover;
      background: lighten($color: $error, $amount: 38%);
    }

    &:active {
      color: $white;
      background-color: $error;
      border-color: $error;
    }

    &:disabled {
      color: $error;
      border-color: rgba($error, .5);
      background-color: $white;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($white, .5);
      }
    }

  }


  .large {
    padding: 16px 32px;

    &.secondary {
      padding: 15px 32px;
    }
  }

  .medium,
  .small {
    font-size: 14px;
  }

  .medium {
    padding: 14px 16px;
  }

  .small {
    padding: 8px 12px;
  }

  .withIcon {
    :global(.MuiButton-label) {
      display: inline-flex;
      gap: 10px;
    }
  }
}