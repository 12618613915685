@import "style/scss/colors.scss";


.btn_download{
  &_value{
    background: #F1F2F5;
    border-radius: 26px; 
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    margin-top: -5px;
    margin-bottom: -5px;
  }
  & > span{
    display: flex !important;
    align-items: center;
  }
  svg{
    margin-right: 16px;
  }
}

.controls{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.export{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-top: 16px;
}