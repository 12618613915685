@import "../../../style/scss/colors.scss";

.icon {
  transition: 0.3s;
  position: absolute;
  top: 50%;
  right: 15px;
  width: 18px;
  height: 18px;
  transform: translateY(-50%) scaleY(1);
  pointer-events: none;
}

.icon-rotate {
  transform: translateY(-50%) scaleY(-1);
}

.menuItem{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &__inner{
    max-width: 100%;

    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  &__text{
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.iconElem{
  width: 25px;
  text-align: center;
  line-height: 0;
  margin-right: 4px;
}
.placeholder{
  color: #3E4555;
  opacity: 0.5;
}


.input{
  border: 1px solid $athens-gray;
  border-radius: 6px;
  min-height: 50px;
  padding: 6px 16px;
  transition: 0.2s;
  color: #38465D;
  font-size: 16px;
  &.Mui-focused {
    border-color: #CFD3DE;
    border: 1px solid #38465D
  }
  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    content: none
  }
  &.MuiInput-input{
    padding: 0
  }

}

