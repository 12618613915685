@import "/src/style/scss/colors.scss";

.wrapper{
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: linear-gradient(to right,#fff 0%,#fff 50%,#F9F9FB 50%,#F9F9FB 100%);
}

.wrapSkip{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 100px;
}