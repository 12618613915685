.ck.ck-editor {
	.ck-editor__editable {
		font-size: 13px;
		line-height: 1.6;
		word-wrap: break-word;
		min-height: 60vh;
		blockquote {
			font-style: italic;
			font-family: Georgia, Times, "Times New Roman", serif;
			padding: 2px 0;
			border-style: solid;
			border-color: #ccc;
			border-width: 0;
		}
		a {
			color: #0782C1;
		}
		ol, ul, dl {
			/* IE7: reset rtl list margin. (#7334) */
			*margin-right: 0px;
			/* Preserved spaces for list items with text direction different than the list. (#6249,#8049)*/
			padding: 0 40px;
		}
		h1, h2, h3, h4, h5, h6 {
			font-weight: normal;
			line-height: 1.2;
		}
		h1 {
			display: block;
			font-size: 2em;
			margin-top: 0.67em;
			margin-bottom: 0.67em;
			margin-left: 0;
			margin-right: 0;
			font-weight: bold;
		}
		h2 {
			display: block;
			font-size: 1.5em;
			margin-top: 0.83em;
			margin-bottom: 0.83em;
			margin-left: 0;
			margin-right: 0;
			font-weight: bold;
		}
		h3 {
			display: block;
			font-size: 1.17em;
			margin-top: 1em;
			margin-bottom: 1em;
			margin-left: 0;
			margin-right: 0;
			font-weight: bold;
		}
		h4 {
			display: block;
			margin-top: 1.33em;
			margin-bottom: 1.33em;
			margin-left: 0;
			margin-right: 0;
			font-weight: bold;
		}
		h5 {
			display: block;
			font-size: .83em;
			margin-top: 1.67em;
			margin-bottom: 1.67em;
			margin-left: 0;
			margin-right: 0;
			font-weight: bold;
		}
		h6 {
			display: block;
			font-size: .67em;
			margin-top: 2.33em;
			margin-bottom: 2.33em;
			margin-left: 0;
			margin-right: 0;
			font-weight: bold;
		}
		hr {
			border: 0px;
			border-top: 1px solid #ccc;
		}
		img.right {
			border: 1px solid #ccc;
			float: right;
			margin-left: 15px;
			padding: 5px;
		}
		img.left {
			border: 1px solid #ccc;
			float: left;
			margin-right: 15px;
			padding: 5px;
		}
		pre {
			white-space: pre-wrap;
			/* CSS 2.1 */
			word-wrap: break-word;
			/* IE7 */
			-moz-tab-size: 4;
			tab-size: 4;
		}
		.marker {
			background-color: Yellow;
		}
		span[lang] {
			font-style: italic;
		}
		figure {
			text-align: center;
			outline: solid 1px #ccc;
			background: rgba(0, 0, 0, 0.05);
			padding: 10px;
			margin: 10px 0px;
			display: inline-block;
		}
		figure>figcaption {
			text-align: center;
			display: block;
			/* For IE8 */
		}
		figure img {
			max-width: 600px !important;
			height: initial !important;
		}
		p img {
			max-width: 600px !important;
			height: initial !important;
		}
		a>img {
			padding: 1px;
			margin: 1px;
			border: none;
			outline: 1px solid #0782C1;
		}
		.cke_contents_ltr blockquote {
			padding-left: 20px;
			padding-right: 8px;
			border-left-width: 5px;
		}
		.cke_contents_rtl blockquote {
			padding-left: 8px;
			padding-right: 20px;
			border-right-width: 5px;
		}
	}
}