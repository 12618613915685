.modal-step-three {
  .upload-next {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .tooltip {
    margin-left: 10px;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
  }

  .upload-label {
    display: flex;

    span {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }

    &__caption {
      margin-left: 32px;
    }
  }
}

.match-table-wrapper {
  margin: 24px -24px;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    box-shadow: inset 0 6px 12px rgba(0, 0, 0, 0.05),
    inset 0 -6px 12px rgba(0, 0, 0, 0.05);
    pointer-events: none;
  }
}
