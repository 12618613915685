@import "/src/style/scss/colors.scss";


.container{
  width: 460px;
  text-align: center;
  margin: 0 auto;
}

.img{
  margin-bottom: 20px;
}