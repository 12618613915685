@import "src/style/scss/colors.scss";

@mixin grid{
    display: grid;
    grid-template-columns: minmax(120px,1.4fr) repeat(3,minmax(100px,1fr)) minmax(110px,1.2fr) minmax(110px,140px) minmax(100px,2fr);
    align-items: center;
    text-align: center;
    padding: 20px 32px;
}


.item{
    padding: 20px 32px;
    margin-bottom: 16px;
    min-height: 75px;
    @include grid;
    background: #FFFFFF;
    border: 1px solid rgba(14, 31, 65, 0.03);
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba($color: #000000, $alpha: 0.1);
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    
    &__block-name{
        display: flex;
        align-items: center;
        gap: 5px;
    }

    &__name{
        max-width: 100%;    
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    &__actions{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 15px;
    }

    &__round{
        display: inline-block;
        font-weight: 500;
        border-radius: 20px;
        background: #F0F2F4;
        border-radius: 50px;
        min-width: 30px;
        line-height: 30px;
        padding: 0 5px;
        cursor: pointer;
    }
}

.header{
    @include grid;
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $gray;
    margin-bottom: 10px;
    
    &>div{
        &:first-of-type{
            text-align: left;
        }
    }
}

.btn{
    &-delete{
        &>span{
            display: grid;
            grid-gap: 10px;
            align-items: center;
        }
    }

    &--edit{
        padding: 10px !important;
    }
}
