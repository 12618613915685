@import "/src/style/scss/colors.scss";

.section{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.title{
  font-weight: 400;
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 15px;
}

.text{
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 36px;
  color: $dark-gray;
}


.assist_text{
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  margin-bottom: 15px;
}

.content{
  margin-top: auto;
  margin-bottom: 90px;
}


.help_block{
  margin-top: 35px;
  padding-top: 30px;
  border-top: 1px solid #BEC4D1;
}

.btn_assist{
  svg path{
    fill: rgb(235, 125, 104) !important;
  }
}

.wrapFields{
  width: 100%;
  max-width: 600px;
}

