@import "/src/style/scss/colors.scss";

.box {
    border-radius: 8px;
    border: 1px solid rgba(14, 31, 65, 0.03);
    background: #FFF;
    box-shadow: 0px 8px 15px 0px rgba(40, 52, 101, 0.08);
    padding: 34px 25px 34px 45px;
    position: relative;
}

.title {
    a {
        margin-left: 8px;
        display: inline-flex;
        vertical-align: super;
    }
}

.position {
    color: $dark-gray;

    a {
        color: $orange;
    }
}

.iconShare.iconShare {
    border-radius: 5px;
    border: 1px solid #E8EAEF;
    padding: 7px 8px !important;
    cursor: pointer;
    z-index: 2;
    line-height: 0;
    font-size: 0;
}

.wrapEmail {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 15px;
}

.email {
    color: $orange;
    display: inline-block;
}

.copy {
    border: none;
    padding: 0;
    font-size: 0;
    line-height: 0;
}

.time {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
    margin-top: 5px;
    font-size: 14px;
    color: $gray;
}


.wrap_info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}


.content {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: -20px;
    margin-right: -10px;
    gap: 14px;
}

.alert.alert {
    margin: 0;
}

.btnContinue.btnContinue {
    line-height: 1.7145;
    padding-top: 6px;
    padding-bottom: 6px;
}