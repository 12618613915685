.Toastify__toast {
  background-color: $white;
  color: $black;
  font-family: Rubik, sans-serif;
  padding: 18px 16px;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgba(52, 69, 134, 0.12);
  margin-bottom: 24px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: $athens-gray;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  .Toastify__progress-bar {
    z-index: 5;
  }

  &--success {
    border: 1px solid $success;

    .Toastify__progress-bar {
      background-color: $success;
    }
  }

  &--error {
    border: 1px solid $error;

    .Toastify__progress-bar {
      background-color: $error;
    }
  }

  &--warning {
    border: 1px solid $attention;

    .Toastify__progress-bar {
      background-color: $attention;
    }
  }

  &--info {
    border: 1px solid $sail;

    .Toastify__progress-bar {
      background-color: $sail;
    }
  }

}

.Toastify__toast-container {
  width: 363px;
}

.Toastify__toast-body {
  padding: 0 6px 0 0;
}

.Toastify__close-button {
  svg {
    fill: $heather;
  }
}

.notification {
  display: flex;

  &-icon {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  &-title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: $black;
    margin-bottom: 4px;

    svg {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  &-text {
    color: $dark-gray;
  }
}
