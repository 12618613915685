@import '../../../../style/scss/variable.scss';
@import 'src/style/scss/colors.scss';


.aside {
  display: flex;
  flex-direction: column;
  background-color: $cinder;
  color: $text-gray;
  width: 100%;
  max-width: $asideWidth;
  height: 100vh;
  overflow-y: auto;
  flex-shrink: 0;
  padding-top: 24px;
  padding-bottom: 96px;

  &_list {
    padding: 24px 0px;

    li {
      position: relative;
    }

    &:not(:last-of-type) {
      margin-bottom: auto;
    }

    &_bottom {
      margin-bottom: 0;
      margin-top: auto;
    }

    &--sub {
      padding: 0px;
    }


    &__item {
      width: 100%;
      color: currentColor;
      padding: 12px 22px;
      position: relative;

      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 22px 1fr;
      align-items: center;
      grid-gap: 12px;
      text-align: left;

      svg {
        margin-top: -3px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 30px;
        background-color: transparent;
        transition: all $transition-fast;
      }

      svg {
        transition: all $transition-fast;

        // fill: currentColor;
        path {
          stroke: currentColor;
        }
      }

      &:hover {
        color: $white;
        text-decoration: none;

        &::before {
          background-color: $white;
        }
      }


      &:global.active {
        color: $white;
        text-decoration: none;

        &::before {
          background-color: $white;
        }
      }

      &--nesting {
        &-1 {
          padding-left: 22px;
        }

        &-2 {
          padding-left: 34px;
        }

        &-3 {
          padding-left: 46px;
        }
      }

      &_btn {
        &::before {
          content: none;
        }

        &::after {
          content: '';
          width: 6px;
          height: 6px;
          border-right: 1px solid currentColor;
          border-bottom: 1px solid currentColor;
          background: transparent;
          position: absolute;
          right: 20px;
          top: 50%;
          transition: 0.3s;
          transform: translateY(-50%) rotate(45deg);

        }

        &--active {
          color: $white;
          text-decoration: none;
        }

        &--open {
          &::after {
            transform: translateY(-50%) rotate(225deg);
          }
        }
      }
    }
  }
}

.logo {
  display: inline-block;
  line-height: 0px;
  font-size: 0px;
  padding: 0px 24px 12px;
}

.logo_link {
  display: inline-block;
}