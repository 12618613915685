@import "/src/style/scss/colors.scss";

.item {
    position: relative;
    padding-left: 27px;
    margin-bottom: 10px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        background-color: $orange;
        border-radius: 100%;
        width: 12px;
        height: 12px;
    }

    &::after {
        content: '';
        position: absolute;
        left: 5px;
        top: calc(50% + 16px);
        width: 2px;
        height: 100%;
        background-color: $orange;
    }

    &:last-of-type {
        &::after {
            height: 60%;
        }
    }

    &.isActive,
    &:first-of-type {
        .item__content {
            &::before {
                content: '';
                width: 99%;
                height: calc(100% - 2px);
                position: absolute;
                z-index: 1;
                background-color: $orange;
                left: 0;
                top: 1px;
            }
        }
    }

    &_markers_up {
        &::before {
            top: 20px;
        }

        &::after {
            height: calc(100% - 12px) !important;
            top: 36px;
        }
    }



    &__content {
        border-radius: 8px;
        border: 1px solid rgba(14, 31, 65, 0.03);
        background-color: #fff;
        box-shadow: 0px 3px 3px 0px rgba(40, 52, 101, 0.03);
        position: relative;
        overflow: hidden;

        &_inner {
            position: relative;
            z-index: 2;
            background-color: #fff;
            padding: 15px 20px;
            padding-left: 23px;
            margin-left: 3px;

        }


    }
}

.common_view {
    &__title {
        font-size: 16px;
        font-weight: 500 !important;
        line-height: 1.5;
    }

    &__time {
        margin-top: 4px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: $gray;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.28;

        span {
            display: flex;
            align-items: center;
            gap: 2px;
        }
    }
}

.keywords_view {
    &__title {
        color: $gray;
        font-size: 16px;
        line-height: 1.12;
        margin-bottom: 15px;
    }


    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        border-top: 1px solid $athens-gray;
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: -15px;
        padding: 10px 20px;
        width: calc(100% + 40px);
    }

    &__source {
        line-height: 1.28;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;

        span {
            color: $gray;
        }

        a {
            display: inline-block;
            color: $orange;
            max-width: 200px;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 200px;
            overflow: hidden;
        }
    }

    &__time {
        margin-top: 4px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: $gray;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.28;

        span {
            display: flex;
            align-items: center;
            gap: 2px;
        }
    }
}

.chips {
    width: auto;

    & &_item {
        background: #F6F6F8;
        font-weight: 500;
        border-radius: 100px;
        font-size: 14px;
        line-height: 22px;
        padding: 3px 15px;
        color: $black;

        svg {
            margin-right: 0px;

            path {
                fill: $black;
            }
        }
    }
}

.link_icon {
    font-size: 0;
    line-height: 0;
}