@import '../../scss/variable.scss';

.page-wrapper {
  display: flex;
}

.page-main {
  background-color: $white;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 71px);
  width: calc(100% - #{$asideWidth});
  margin-top: 71px;
  display: flex;
  flex-direction: column;
  position: relative; // relative for circular progress

  header {
    position: fixed;
    top: 0;
    left:  $asideWidth;
    right: 0;
    height: 71px;
    z-index: 1250;
    display: flex;
    align-items: center;
  }
}

.header {
  &-user-box {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 4px 0;
  }
}
