@import "../../../style/scss/colors.scss";


.menu{
  background: #FFFFFF;
  border: 1px solid #E8EAEF;
  box-shadow: 0px 1px 5px rgba(40, 52, 101, 0.025), 0px 6px 10px rgba(40, 52, 101, 0.08);
  border-radius: 6px;
  overflow: auto;
  max-width: 450px;
  min-width: 150px;
  &__header{
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E8EAEF;
    padding-left: 20px;
    padding-right: 20px;
  }
  &__body{
    padding: 6px;
    :global{
      ul{
        position: relative;
      }
    }
  }

  &__footer{
    height: 50px;
    display: flex;
    align-items: center;
    border-top: 1px solid #E8EAEF;
    padding-left: 20px;
    padding-right: 20px;
  }
}

