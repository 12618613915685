@import "../../../style/scss/colors";

.dropzone {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border-width: 2px;
  border-radius: 8px;
  color: $black;
  border-color: $athens-gray;
  border-style: dashed;
  background-color: $white;
  outline: none;
  transition: all .24s ease-in-out;
  cursor: pointer;

  &:focus, &:hover {
    background-color: $athens-gray-light;
  }

  &.disabled {
    opacity: 0.6;
  }

  &_gray {
    background-color: $athens-gray-light;
    flex-wrap: wrap;
    padding: 9px 20px;
  }
  &_error{
    border-color: $error;
  }
}

.dropzone_inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 14px;
  svg {
    margin-right: 16px;
  }

  span:last-of-type {
    color: $gray;
  }

  &__dark {
    color: $black;
  }

  &__light {
    color: $gray;
  }
}

.dropzone_inputCaption {
  &:not(:empty){
    margin-top: 16px;
  }
}

.dropzone_avatar {
  display: flex;
  padding: 5px 0;

  &Caption {
    margin-left: 16px;
  }
}


.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    vertical-align: middle;
  }

  svg {
    margin-right: 8px;
    vertical-align: middle;
    flex-shrink: 0;
  }

  &_text {
    display: inline-flex;
  }

  &_name {
    overflow-wrap: anywhere;
  }
}

.size {
  margin-left: auto;
  margin-right: 16px;
  padding-left: 16px;
  color: $gray;
  flex-shrink: 0;
}

.close {
  cursor: pointer;
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 1px;
    height: 11px;
    background-color: $pickled-bluewood;
    transition: background-color .3s;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 1px;
    height: 11px;
    background-color: $pickled-bluewood;
    transition: background-color .3s;
  }

  &:hover {
    &::before,
    &::after {
      background-color: $error;
    }
  }
}

.info {
  margin-left: 10px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}

.error {
  display: inline-block;
  margin-top: 8px;
  color: #EF5E39;
}
.warning{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
