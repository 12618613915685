@import '../../../style/scss/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
}

.footer {
  padding: 20px 0;
  flex-shrink: 0;
  border-top: 1px solid $athens-gray;
}

.header {
  padding: 10px 0;
  position: relative;
  background-color: $white;
  border-bottom: 1px solid $athens-gray;
}

.main {
  align-items: center;
  justify-content: center;
  padding: 20px 10px 30px;
}

.link {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #8f97ab;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #3f4554;
    border-bottom: 1px solid #3f4554;
  }
}

.list {
  display: flex;
}

.ListItem {
  margin-left: 10px;
  margin-right: 10px;
}

.ListLink {
  font-size: 14px;
  line-height: 1.5;
  color: $dark-gray;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: lighten($dark-gray, 15%);
    text-decoration: underline;
  }
}

.text {
  font-size: 14px;
  line-height: 1.5;
  color: $dark-gray;
  font-weight: 400;
}

.div{
  display: grid;
  grid-template-columns: repeat(3,1fr);
}
.alert_notification{
  margin-left: 24px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  background: #000;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 4px;
  background: $error;
  p{
    text-transform: uppercase;
  }
  svg{
    margin-right: 8px;
  }
}