@import "../../../style/scss/colors";

.rc-table{
    $this: &;
    border-collapse: collapse;
    &-cell{
        background-color: $white;
        transition: background-color 0.3s;
        padding: 10px 16px;
        position: relative;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $black;

        &-fix-left,
        &-fix-left-last,
        &-fix-right,
        &-fix-right-last
        {
            z-index: 2;
        }
        &-fix-left,
        &-fix-left-last,
        &-fix-right,
        &-fix-right-last{
            &:after{
                content: "";
                pointer-events: none;
                transition: box-shadow .3s;
                position: absolute;
                top: 0;
                bottom: -1px;
                width: 20px;
                height: 99%;
            }
        }
        &-fix-left,
        &-fix-left-last{
            &:after{
                right: -1px;
                transform: translateX(100%);
            }
        }
        &-fix-right,
        &-fix-right-last{
            &:after{
                left: -1px;
                transform: translateX(-100%);
            }
        }
    }
    &-row{
        &:hover{
            #{$this}-cell{
                background-color: $athens-gray-light;
            }
        }
    }
    &-thead{
        #{$this}-cell{
            padding: 5px 16px;
        }
    }
    th{
        text-align: left;
        //border-bottom: 1px solid #E8EAEF;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $gray;
    }
    &-ping-left &-cell-fix-left-first,
    &-ping-left &-cell-fix-left-last {
        &:after{
            box-shadow: inset 10px 0 8px -8px rgba($color: #000000, $alpha: 0.2)
        }
    }
    &-ping-right &-cell-fix-right-first,
    &-ping-right &-cell-fix-right-last {
        &:after{
            box-shadow: inset -10px 0 8px -8px rgba($color: #000000, $alpha: 0.2)
        }
    }
    table{
        width: 100%;
    }
    &-sticky-header {
        position: -webkit-sticky;
        position: sticky;
        z-index: 3
    }
    &-expanded-row-fixed{
        max-width: 100%;
    }
    .rc-table-expand-icon-col{
        width: 50px;
    }
}
