@import "/src/style/scss/colors.scss";

.footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 100px;
  flex: 0 0 auto;
}


.text {
  font-size: 14px;
  line-height: 1.5;
  color: $dark-gray;
  font-weight: 400;
}