@import "/src/style/scss/colors.scss";

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #E8EAEF;
  margin-bottom: 30px;
}

.item {
  padding: 15px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border-bottom: 3px solid transparent;
  color: $black;
  transition: 0.3s;
  text-decoration: none !important;

  &:global(.active) {
    border-color: $orange;
    color: $orange;
  }

  &:hover {
    color: $orange;
  }
}