@import "src/style/scss/colors";


.table {
  padding: 24px;
  position: relative;
  max-height: 360px;
  overflow-y: auto;

  &_inner {
    border: 1px solid $athens-gray;
    border-radius: 8px;
    overflow: hidden;
    background-color: $white;
  }
}

.row {
  display: flex;
  border-bottom: 1px solid $athens-gray;

  &:last-child {
    border-bottom: none;
  }
}

.cell {
  width: 50%;
  flex-shrink: 0;
  border-right: 1px solid $athens-gray;
  padding: 16px;

  &:last-child {
    border-right: none;
  }
}

.cell_title {
  margin-bottom: 4px;
}

.text {
  color: $dark-gray;
  word-wrap: break-word;
}

.match_caption {
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
}
