@import "/src/style/scss/colors.scss";

.block{
    margin: 15px 0;
}

.header{
    border-radius: 8px;
    border: 1px solid rgba(14, 31, 65, 0.05);
    background: #FFF;
    box-shadow: 0px 4px 5px -2px rgba(40, 52, 101, 0.08);
    padding: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

    &__marker_new{
        border-radius: 100px;
        border: 1px solid #FAE2BC;
        background: #FDF3E3;
        position: absolute;
        top: 0px;
        right: 10px;
        transform: translateY(-50%);
        text-align: center;
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
        padding: 3px 6px;
        z-index: 2;
    }
}

.title{
    font-size: 18px;
    font-weight: 500;
}

.info{
    display: grid;
    align-items: center;
    grid-gap: 10px;
    grid-template-columns: 24px 1fr;
}

.marker{
    color: $gray;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
}

.body{
    border-radius: 0px 0px 8px 8px;
    background: #F7F8FA;
    max-width: calc(100% - 14px);
    margin: 0 auto;
    padding: 25px;
}