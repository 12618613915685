@import "/src/style/scss/colors.scss";

.main{
  flex: 1 0 auto;
}

.container{
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding-bottom: 20px;
}