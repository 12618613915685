@import "/src/style/scss/colors.scss";

.header{
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px; 
  color: $dark-gray;
  
  :global(.MuiTypography-root){
    justify-content: center;
    margin-bottom: 5px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 34px; 
    color: $black;
  }
}

.input.input{
  min-height: 200px;
}

.footer{
  text-align: center;
  padding-bottom: 20px;
  padding-top: 10px;
}