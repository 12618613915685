html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  .no-margin {
    margin: 0;
  }
}


.login-box {
  margin: auto;
  padding: 30px;
  max-width: 540px;
  min-width: 300px;
  width: 100%;
  background-color: $white;
  border: 1px solid $athens-gray;
  border-radius: 8px;
  box-shadow: 0 5px 41px rgba(15, 16, 23, 0.03);

  @include m('sm') {
    padding: 56px;
  }
}

.login-box-bottom {
  text-align: center;

  &__confirmation {
    display: inline-block;
    margin-top: 32px;
    color: $gray;

    a {
      color: $gray;
      text-decoration: underline;
      font-weight: inherit;
      transition: all $transition-fast;

      &:hover {
        color: darken($gray, 20%);
      }
    }
  }
}


.user-form {
  .before-button-space {
    margin-top: 8px;
  }
}

.verification-icon {
  margin-left: auto;
  margin-right: auto;
  width: 64px;
  height: 64px;
}


.pageNotFound {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  height: 100vh;
  font-size: 120px;
  color: red;
  font-weight: 700;
}

.required {
  color: $orange;
  //display: block;
  margin-right: auto;
}

.block-title {
  padding: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $athens-gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.menu-button {
  cursor: pointer;
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &-icon {
    @extend .menu-button;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
}

.text-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;

  &--1 {
    -webkit-line-clamp: 1;
  }

  &--word {
    word-break: break-word;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 50px;
  margin: 24px auto 50px;
  padding: 0 48px;
}