@import "../../../style/scss/colors.scss";

.hide_content{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba($color: #fff, $alpha: 0.8);
  background: rgba(247, 248, 250, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  svg{
    margin-bottom: 30px;
  }
  p{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 22px;
  }
}

.hide_content_container{
  position: relative;
  overflow: hidden;
}