@import "src/style/scss/colors.scss";

.filter {
  border: 1px solid #F7F8FA;
  box-shadow: 0px 8px 20px rgba(40, 52, 101, 0.102);
  border-radius: 8px;
  background: #FFFFFF;


  &__body {
    padding: 16px 24px;
  }

  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 50px;
    gap: 10px;
  }

  &__item {
    margin-bottom: 0px !important;
  }

  &__chips {
    width: auto !important;

    & &-item {
      background: #F6F6F8;
      font-weight: 500;
      border-radius: 100px;
      font-size: 14px;
      line-height: 22px;
      padding: 3px 15px;
      color: $black;

      svg {
        margin-right: 0px;

        path {
          fill: $black;
        }
      }
    }
  }
}

.btn-add-filter {
  background: #FFFFFF !important;
  border: 1px solid #EB7D68 !important;
  border-radius: 100px !important;
  padding: 10px 24px !important;
  text-align: center !important;
  height: 50px !important;

  &:hover {
    box-shadow: 0px 0px 4px rgba(235, 125, 104, 0.7) !important;
  }

  svg {
    color: $orange;
  }

  :global {
    .MuiButton-label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}

// .btn-filter{
//   background: #F6F6F8;
//   border-radius: 100px;
//   max-width: 385px;
//   flex-shrink: 0;
//   padding: 6px 20px 6px 24px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   &:hover{
//     background: #FDF5F4;
//   }
//   &__name{
//     color: $orange;
//     font-weight: 500;
//   }
//   &__text{
//     max-width: calc(100% - 35px);
//     white-space: nowrap;
//     line-height: 1.8;
//     overflow: hidden ;
//     text-overflow: ellipsis;
//   }
//   &-close{
//     flex-shrink: 0;
//     cursor: pointer;
//     border-radius: 50%;
//     background: #FFFFFF;
//     border: 1px solid #E1E4EC;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 25px;
//     height: 25px;
//     &:hover{
//       background-color: $orange;
//       :global{
//         path{
//           fill: #fff
//         }
//       }
//     }
//   }
// }

.filter-item {
  display: grid;
  align-items: center;
  grid-template-columns: 30px 1fr;

  &__multiple {
    display: flex;
    align-items: center;
  }
}