.profile-nav {
  display: flex;
  height: 55px;
  padding: 0 24px;
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(40, 52, 101, 0.102);

  &__link {
    margin-right: 32px;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    line-height: 55px;
    color: $dark-gray;
    transition: color $transition-fast;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: transparent;
      transition: background-color $transition-fast;
      border-radius: 2px 2px 0 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &.active {
      color: $orange;
      text-decoration: none;

      &::after {
        background-color: $orange;
      }
    }
  }
}