$FONTW: 300,400,500,600,700;
$FONTS: 12,13,14,15,16,18,20;
$FONTSMD: 0;
$FONTSLG: 0;
$FONTSXL: 0;


.font{
    @each $var in $FONTW {
        &--#{$var}{
            font-weight: $var;
        }
    }

    @each $var in $FONTS {
        &--#{$var}{
            font-size: #{$var}px;
        }
    }

    &-md{
        @include m(md){
            @each $var in $FONTSMD {
                &--#{$var}{
                    font-size: #{$var}px;
                }
            }
        }
    }
   
    &-lg{
        @include m(lg){
            @each $var in $FONTSLG {
                &--#{$var}{
                    font-size: #{$var}px;
                }
            }
        }
    }

    &-xl{
        @include m(xl){
            @each $var in $FONTSXL {
                &--#{$var}{
                    font-size: #{$var}px;
                }
            }
        }
    }

}

@mixin TEXTALIGN {
    &--center{
        text-align: center;
    }
    &--left{
        text-align: left;
    }
    &--right{
        text-align: right;
    }
}
.text{
    @include TEXTALIGN;
    @each $var in $EACHMEDIA {
        @include m($var){
            &-#{$var}{ 
                @include TEXTALIGN;
            }
        }
    }
}

.underline{
    text-decoration: underline;
}