.title {
  &-h1 {
    font-weight: 300;
    font-size: 38px;
    line-height: 55px;
  }

  &-main,
  &-h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
    color: $black;
    margin-bottom: 16px;
  }

  &-h2 {
    margin-bottom: 8px;
  }

  &-h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
  }

  &-h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $black;
  }

  &-h5,
  &-subheading {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;

    &_bold {
      font-weight: 500;
    }
  }

  &-subheading-icon {
    margin-right: 16px;
    font-size: 0px;
    line-height: 0px;
    max-height: 80px;
    max-width: 80px;

    img {
      max-height: 80px;
    }
  }
}

.title-for-box {
  padding-bottom: 24px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 48px);
    height: 1px;
    background-color: $athens-gray;
  }
}

.title-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-h2 {
    margin-bottom: 0px;
  }
}

.subtitle-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &_left-align {
    justify-content: flex-start;
  }

  .relative {
    flex-shrink: 0;
    margin-top: -27px;
  }

  a.button {
    margin-top: -27px;
  }

  .main-text {
    color: $dark-gray;

    &__multiple-items {
      display: inline-block;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.subtitle {
  &_gray {
    color: $gray;
  }
}

.text-centered {
  text-align: center;
}

.main-text {
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;

  &_bold {
    font-weight: 500;
  }
}

.small-text {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;

  &_bold {
    font-weight: 500;
  }
}

.sub-text {
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;

  &_bold {
    font-weight: 500;
  }
}

.orange-link {
  display: inline;
  color: $orange;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

.no-wrap {
  white-space: nowrap;
}

.link-in-menu {
  color: $black;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    border-bottom: none;
  }
}

.relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.icon-pointer {
  cursor: pointer;
  user-select: none;

  svg path {
    transition: all $transition-fast;
  }

  &:hover {
    svg path {
      fill: $orange;

    }
  }
}

.with-icon {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 22px 1fr;
  align-items: center;
  grid-gap: 16px;

  &_icon-right {
    grid-template-columns: 1fr 22px;
  }

  svg {
    margin-top: -3px;
  }
}

.highlighted {
  color: $orange;

  svg {
    fill: $orange;
  }
}

.animate-left {
  &:hover {
    svg {
      animation-duration: $transition-normal;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
      animation-play-state: running;
      animation-name: bounceLeft;
    }
  }
}

.checkbox {
  &.checkbox-label span {
    font-size: 16px;
    color: $black;
  }
}

.d {
  &-inline-block {
    display: inline-block;
  }

  &-block {
    display: block;
  }
}

@keyframes bounceLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(3px);
  }
}