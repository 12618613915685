@import "/src/style/scss/colors.scss";

.container{
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 40px;
  position: relative;
  gap: 20px;
}
.col{
  width: calc(50% - 10px);
  padding: 20px;
  position: relative;
}
.title{
  width: 100%;
  font-size: 24px;  
  color: #000;
  font-weight: 700;
}