.table{
    box-shadow: 0 8px 20px rgba(40, 52, 101, 0.102);
    border-radius: 4px;
    border: 1px solid $athens-gray-light;
    position: relative;
}
.tableEmpty{
    .rc-table-body{
        overflow: hidden !important;
    }
}