@import "src/style/scss/colors.scss";
@import "src/style/scss/mixin/mixin";


.wrapper {
    background-color: #B4B4B6;
    margin: 24px -48px;
    padding: 48px;
    width: calc(100% + 48px + 48px);
}

.wrapper_inner {
    border-radius: 8px;
    background: #fff;
    padding: 25px;

    &_header {
        text-align: center;
    }
}

.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(245px, 1fr));
    gap: 20px;
    padding: 20px 0;
    flex-wrap: wrap;
}

.priceCard {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 8px 15px 0px rgba(40, 52, 101, 0.08);
    position: relative;
    display: flex;
    flex-direction: column;

    &__show_details {
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        gap: 5px;
        color: #9298A8;

        svg {
            margin-top: 2px;
        }
    }

    &__body {
        padding: 23px 25px;
        flex-grow: 1;
    }

    &__footer {
        padding: 25px;

        a {
            width: 100%;
        }
    }

    &__header {
        padding-top: 39px;
        padding-bottom: 30px;
        text-align: center;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: calc(100% - 50px);
            left: 50%;
            bottom: 0;
            height: 1px;
            transform: translateX(-50%);
            background: rgba(228, 231, 237, 0.5);
        }
    }


    &__list {
        margin-top: 10px;

        li {
            padding-left: 32px;
            position: relative;
            color: $black;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.625;
            margin-top: 7px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26" fill="none"><circle cx="11" cy="13" r="11" fill="%2386C271"/><path d="M6 12L10 16L16 10" stroke="white" stroke-width="2"/></svg>');
                width: 22px;
                height: 26px;
            }
        }

        &_fill.priceCard__list_fill {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right: 16px;
            padding-left: 23px;
            border-radius: 6px;
            margin-left: 8px;
            font-size: 14px;
            background: rgba($color: #e0f2d9, $alpha: 0.5);

            &::before {
                left: -8px;
                top: 50%;
                transform: translateY(-50%);
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><circle cx="11" cy="11" r="11" fill="%2386C271"/><path d="M11.0001 6.59998V15.4M15.4001 11L6.6001 11" stroke="white" stroke-width="2"/></svg>');
                height: 22px;
            }
        }

        &_disable.priceCard__list_disable {
            color: rgba(131, 135, 147, 0.5);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.16px;

            &::before {
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26" fill="none"><circle cx="11" cy="13" r="11" fill="%23E4E7ED"/><path d="M7 17L15 9M15 17L7 9" stroke="white" stroke-width="2"/></svg>');
            }
        }
    }

    &__price_from {
        color: rgba(131, 135, 147, 0.7);
        font-size: 20px;
        font-weight: 400;
    }

    &__price_currency {
        color: $orange;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        vertical-align: super;
    }

    &__price_period {
        color: rgba(131, 135, 147, 0.7);
        font-size: 20px;
        font-weight: 400;
    }

    &__price_value {
        color: $orange;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1.2px;
    }

    &__sub_title {
        color: $text-gray;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.37;
        letter-spacing: 0.16px;

        &:not(:first-of-type) {
            margin-top: 15px;
        }
    }

    &__title {
        color: $black;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.16;
        letter-spacing: 0.48px;
        margin-bottom: 0;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &__popular {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 6px 20px;
        border-radius: 3px 3px 10px 10px;
        text-wrap: nowrap;
        background: linear-gradient(0deg,
                rgba(255, 255, 255, 0.5) 0%,
                rgba(255, 255, 255, 0.5) 100%),
            #ffcea1;
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translateX(-50%);

        &::before {
            content: "";
            width: 18px;
            height: 19px;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none"><path d="M4.4445 16.2876C5.10404 16.8709 5.88513 17.2808 6.72375 17.4838C6.804 17.5116 6.858 17.4007 6.804 17.3453C5.00775 15.5363 5.99475 13.5786 6.72375 12.6958C7.2435 12.0657 7.98375 11.0278 7.92975 9.63605C7.92975 9.49672 8.06475 9.38509 8.17125 9.46901C9.1905 10.0256 9.88725 11.2503 10.0747 12.2517C10.3702 11.9461 10.4775 11.4727 10.4775 11.0555C10.4775 10.9162 10.638 10.8046 10.7722 10.9162C11.7375 11.8345 13.3995 14.9513 10.7182 17.3999C10.665 17.4561 10.7182 17.5678 10.7722 17.5401C11.5853 17.3151 12.3498 16.9275 13.0245 16.3985C17.3947 12.836 14.5522 6.51847 12.702 4.43163C12.4612 4.18068 12.0322 4.34772 12.0322 4.70951C12.0052 5.46159 11.7907 6.29601 11.2282 6.85255C10.7992 4.96047 9.36975 2.81109 7.332 1.75343C7.06425 1.61409 6.7425 1.83655 6.7695 2.14293C6.822 4.73168 5.22225 6.37993 3.828 8.52297C2.59425 10.4428 1.79025 13.9776 4.4445 16.2876Z" fill="%23EB7D68"/></svg>');
        }

        &::after {
            content: "Most popular";
            white-space: nowrap;
            color: $orange;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.37;
            letter-spacing: 0.16px;
        }
    }
}