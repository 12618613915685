@import "/src/style/scss/colors.scss";
@import "/src/style/scss/mixin/mixin.scss";


$gridTemplateColums: 1fr 2fr auto auto;


.container {
  margin-bottom: 20px;
  position: relative;

  &__content {
    margin-bottom: 20px;

    &:empty {
      height: 60vh;
    }
  }

  &__header {
    padding: 5px 40px;
    display: grid;
    align-items: center;
    grid-template-columns: $gridTemplateColums;
    grid-gap: 40px;
    color: $gray;
    margin-bottom: 5px;
  }
}

.header_controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;

  &__item {
    margin-left: 16px;
  }
}

.card {
  margin-bottom: 16px;

  &__content {
    background: #FFFFFF;
    border: 1px solid #EDEFF2;
    box-shadow: 0px 10px 10px rgba($color: #000000, $alpha: 0.05);
    border-radius: 8px;
    padding: 30px 40px;
    display: grid;
    align-items: center;
    grid-template-columns: $gridTemplateColums;
    grid-gap: 40px;
  }

  &__info {

    &_name {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
    }

    &_site {
      font-size: 14px;
      line-height: 22px;
      color: $orange;
      margin-bottom: 8px;

      a {
        color: currentColor;
      }
    }

  }


  &__trigger {
    &_list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      li {
        background: #F6F6F8;
        font-weight: 500;
        border-radius: 100px;
        font-size: 14px;
        line-height: 22px;
        padding: 3px 15px;
      }
    }

    &_btn {
      color: $orange;
      display: inline-flex;
      align-items: center;
      gap: 5px;
    }
  }

  &__additional {
    background: #F7F8FA;
    border-radius: 0px 0px 16px 16px;
    padding: 20px;
    padding-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;

    &_title {
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 16px;
    }

    &_content {
      display: grid;
      grid-gap: 16px;

      @include m(lg) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include m(xl) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include m(mHd) {
        grid-template-columns: repeat(4, 1fr);
      }

      @include m(fHd) {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &_footer {
      text-align: center;
      padding-top: 9px;
      padding-bottom: 10px;

      button {
        display: inline-flex;
        align-items: center;
        line-height: 22px;
        gap: 8px;
      }
    }
  }

  &__btn_info {
    margin-left: auto;
    display: flex !important;
  }

}

.btn-toggle {
  &>span {
    display: inline-flex;
    align-items: center;
    line-height: 22px;
    gap: 8px;
  }

  svg path {
    fill: none !important;
  }
}

.arrow-up {
  transform: scale(-1);
}

.hide_content {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(247, 248, 250, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;

  svg {
    margin-bottom: 30px;
  }

  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 22px;
  }
}

.hide_content_container {
  position: relative;
}