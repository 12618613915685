@import "../../../style/scss/colors";

.header {
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 19px 16px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: $athens-gray;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: $black;
    flex-shrink: 0;
    margin-right: 15px;
  }

  &__ctrl {
    flex-grow: 1;
    margin-right: -16px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $athens-gray;
  padding: 16px 20px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: -16px;
  &__item {
    flex-shrink: 0;
    margin-right: 16px;
    font-size: 14px;
    line-height: 22px;
    color: $gray;
    @for $i from 0 through 6 {
      &_order_#{$i} {
        order: #{$i};
      }
    }
  }
}

.search{
  flex-grow: 1;
  max-width: 340px;
  flex-shrink: 1;
  :global{
    .MuiFormControl-root{
      width: 100%;
    }
  }
  &__elem{
    :global{
      .MuiInputBase-root{
        width: 100%;
      }
    }
  }
}

.container_render{
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
}

.container_render_empty{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}