@import 'src/style/scss/colors.scss';

.container{
    background: #FFFFFF;
    border: 1px solid $athens-gray-light;
    box-shadow: 0px 8px 20px rgba(40, 52, 101, 0.102);
    border-radius: 8px;
    display: flex;
    min-height: #{'max(630px, 70vh)'};
}

.aside{
    border-right: 1px solid $athens-gray;
    flex-basis: 320px;
    padding: 30px;

    &__title{
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: $text-gray;
        text-transform: uppercase;
    }
}

.content{
    flex-grow: 1;
    padding: 30px;
}