@import 'src/style/scss/colors.scss';



.section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.title {
  font-weight: 400;
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 40px;
}


.item {
  padding: 20px 35px;
  min-height: 100px;
  border-radius: 8px;
  margin-bottom: 10px;
  border: 1px solid rgba(14, 31, 65, 0.07);
  box-shadow: 0px 8px 15px rgba(40, 52, 101, 0.05);

  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $dark-gray;
  }

  &__value {
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    color: $orange;
  }


  &__info {
    margin-top: 5px;
  }
}