@import '../colors.scss';

body {

  .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
  }

  .MuiMenu-paper {
    max-height: 300px;
  }

  .MuiChip-root {
    margin: 2px;
  }

  .MuiInput-root.Mui-disabled {
    color: #9298A8;
    background-color: $athens-gray-light;
    ;
  }

  .MuiFormHelperText-root.Mui-error {
    color: $error;
    font-size: 16px;
    line-height: 26px;
    margin-top: 0;
    animation: fade-in 0.2s backwards;
  }

  .MuiPopover-paper {
    margin-top: 4px;
  }

  .MuiMenu-list {
    padding: 8px;
  }

  .MuiMenuItem-root {
    color: $black;
    padding: 8px;
    font-size: 16px;
    line-height: 26px;

    &:focus {
      background-color: transparent;
    }

    &:hover {
      background-color: $athens-gray-light !important;
    }
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: $athens-gray-light;
    border-radius: 4px;
  }

  .MuiAutocomplete-root {
    margin-top: 16px;
    margin-bottom: 3px;

    .MuiInputLabel-root {
      transform: none;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $dark-gray;
      position: static;
      margin-bottom: 4px;
    }

    .MuiFormLabel-asterisk {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: $orange;
    }

    .MuiInput-formControl {
      margin-top: 0;
    }

    .Mui-focused {
      &::after {
        display: none;
      }
    }

    .MuiInput-underline {
      &::before {
        border-bottom: none !important;
      }
    }

    .MuiInputBase-root {
      height: 50px;
      border-radius: 6px;
      border: 1px solid $athens-gray;
      background-color: $white;
      padding-left: 12px;
    }
  }

  .MuiAutocomplete-listbox {
    max-height: 37vh;
  }

  .MuiAutocomplete-popper {
    .MuiAutocomplete-option {
      border-radius: 4px;
      margin: 0 8px;
      color: $black;

      &[aria-selected="true"] {
        background-color: $athens-gray-light;
      }
    }
  }

  .MuiTabs-root {
    min-height: auto;
  }

  .MuiTab-root {
    opacity: 1;
    min-width: auto;
    margin-right: 20px;
    padding: 4px 0px;
    min-height: auto;
    text-transform: none;

    &.Mui-selected {
      color: $orange;
    }
  }

  .MuiTabs-indicator {
    background-color: $orange;
  }

  .MuiPickersDay-daySelected,
  .MuiPickersDay-day:focus.MuiPickersDay-daySelected {
    background-color: $orange;
  }

  .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background-color: rgba($color: $orange, $alpha: 0.4);
  }
}

.alert {
  margin-top: 4px;
  margin-bottom: 4px;

  .MuiAlert-root {
    border-radius: 6px;
    padding: 6px 10px;
  }


  .MuiAlert-standardWarning {
    background: #FFFFFD;
    border: 1px solid #FFE297;
  }

  &--error {
    .MuiAlert-standardWarning {
      background: rgba(239, 94, 57, 0.11);
      border: 1px solid #EF5E39;
    }
  }

  .MuiAlert-message {
    display: flex;
    align-items: center;
    padding: 0;
    color: $black;

    svg {
      margin-right: 6px;
      flex-shrink: 0;
    }
  }

  &-multiple {
    .MuiAlert-message {
      flex-wrap: wrap;
    }

    &-item {
      width: 100%;
      margin-bottom: 8px;
      display: flex;

      svg {
        flex-shrink: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.MuiPopover-paper-top {
  .MuiPopover-paper {
    margin-top: 0px;
    transform: translateY(-4px) !important;
  }
}

.notifications {
  .MuiBadge-colorError {
    background-color: $error;
  }
}

.user {
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  svg {
    transform: scaleY(1);
    transition: transform $transition-fast;
    margin-left: 18px;
  }

  &:hover {
    svg {
      transform: scaleY(-1);
    }
  }
}

.modal-step-one,
.step-one,
.long-radios {
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: stretch;
  }

  .MuiFormControlLabel-root {
    flex-basis: 50%;
    display: block;
    margin: 0 12px;
    position: relative;

    &.Mui-disabled {
      cursor: not-allowed;

      .radio-icon {
        filter: grayscale(1);
      }

      .MuiTypography-root {
        &:hover {
          border-color: $athens-gray;
        }
      }
    }
  }

  .MuiRadio-colorPrimary {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .MuiTypography-root {
    display: block;
    width: 100%;
    padding: 12px;
    border: 1px solid $athens-gray;
    border-radius: 4px;
    transition: border-color $transition-normal;

    &:hover {
      border-color: $orange;
    }
  }

  .Mui-checked {
    &~.MuiTypography-root {
      border-color: $orange;
    }
  }
}

.long-radios {
  .MuiFormGroup-root {
    display: flex;
    flex-direction: column;
  }

  .MuiFormControlLabel-root {
    margin: 0 0 8px;
    background-color: $white;
  }

  .MuiTypography-root {
    padding: 0 60px 0 0;
  }

  .MuiRadio-colorPrimary {
    top: 50%;
    left: auto;
    right: 24px;
    transform: translateY(-50%);
  }

  &--current {
    margin-bottom: 40px;
  }
}

.step-one {
  .MuiFormControlLabel-root {
    display: flex;
    flex-direction: row-reverse;
    flex-basis: unset;
    width: 446px;
    margin: 0 0 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .MuiFormGroup-root {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .MuiTypography-root {
    border-radius: 8px;
  }

  .MuiRadio-colorPrimary {
    position: relative;
    top: unset;
    left: unset;
    right: 36px;
  }
}

.switch {
  &.MuiSwitch-root {
    width: 35px;
    height: 20px;
    padding: 0;
  }

  .MuiSwitch-switchBase {
    padding: 0px;
    width: 100%;
    height: 100%;
    transform: none !important;
    background: transparent !important;

    // position: relative;
    .MuiSwitch-thumb {
      padding: 0;
      color: $white;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 0;
      position: absolute;
      transform: translate(2px, -50%);
      background-color: $white;
      transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &.Mui-checked {
      .MuiSwitch-thumb {
        transform: translate(17px, -50%);
      }
    }
  }

  .MuiIconButton-label {
    width: 100%;
    height: 100%;
  }

  .MuiSwitch-input {
    width: 50px;
    height: 25px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .MuiSwitch-thumb {
    width: inherit;
    height: inherit;
    box-shadow: none;
  }

  .MuiSwitch-colorPrimary.Mui-checked,
  .MuiSwitch-colorSecondary.Mui-checked {
    color: $white;

    &+.MuiSwitch-track {
      background-color: $orange;
      opacity: 1;
    }
  }

  .MuiSwitch-track {
    opacity: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    background-color: $mischka;
  }

  &_dark {
    .MuiSwitch-colorPrimary.Mui-checked {
      color: $white;

      &+.MuiSwitch-track {
        background-color: $manatee;
        opacity: 1;
      }
    }

    .MuiSwitch-track {
      background-color: $black;
    }
  }

  &_disabled {
    pointer-events: none;
    opacity: .5;
  }
}

.radio {
  .MuiRadio-root {
    width: 20px;
    height: 20px;
    padding: 0;
    color: $mischka;
    border: 1px solid $mischka;
    transition: all $transition-normal;
  }

  .MuiRadio-colorPrimary {
    transition: all $transition-normal;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: transparent;
      transition: all $transition-normal;
    }

    &:hover {
      background-color: $orange;
      border-color: $orange;

      &::after {
        background-color: $white;
      }
    }
  }

  .MuiSvgIcon-root {
    display: none;
  }

  .MuiRadio-colorPrimary.Mui-checked {
    background-color: $orange;
    border-color: $orange;

    &::after {
      background-color: $white;
    }

    &:hover {
      background-color: darken($orange, 10%);
      border-color: darken($orange, 10%);
    }
  }
}

.MuiPagination-ul {
  .MuiPaginationItem-page {
    padding: 0;
    min-width: 16px;
    color: $manatee;
    font-weight: 400;
    transition: all $transition-fast;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: transparent;
      height: 2px;
      width: 0;
      transition: all $transition-fast;
    }

    &:hover {
      color: $cinder;
      background-color: transparent;
    }

    &.Mui-selected {
      background-color: transparent;
      color: $cinder;
      font-weight: 500;
      padding: 0;
      min-width: 16px;

      &::after {
        background-color: $orange;
        width: 125%;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.edit-title-form {
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  .MuiFormControl-root {
    margin: 0;
    margin-right: 32px;
    width: 50%;
    min-width: 280px;
    max-width: 700px;
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
  }

  .MuiInput-root {
    border: none;
    padding: 0;
    min-height: unset;
    height: 42px;
  }

  .MuiInputBase-input {
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
  }

  .relative {
    display: inline-block;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-header {
  .MuiAvatar-root {
    width: 32px;
    height: 32px;
  }

  .MuiAvatarGroup-root {
    flex-direction: row-reverse;
  }

  .MuiAvatarGroup-avatar:first-child {
    margin-left: -8px;
  }
}

.table-title_with-avatar {
  display: flex;
  align-items: center;
  padding: 5px 0;

  .MuiAvatar-root {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    flex-shrink: 0;
  }
}

.account-columns-wrapper {
  margin-bottom: 20px !important;

  &.MuiGrid-container {
    width: auto;
  }

  .MuiAvatar-root {
    width: 56px;
    height: 56px;
  }
}

.MuiTooltip-popper {
  a {
    color: $orange;
    text-decoration: underline;
    transition: color $transition-normal;

    &:hover {
      text-decoration: none;
      color: $attention;
    }
  }
}

.MuiTooltip-tooltip {
  padding: 6px;
  background-color: $cinder !important;
  color: $white;
  font-size: 14px !important;
  line-height: 22px !important;
  max-width: 450px !important;
}

.owner-cell {
  .MuiAvatar-root {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.rc-table-thead {
  .MuiBox-root {
    font-weight: normal;
    font-size: 14px !important;
    line-height: 22px !important;
    color: $gray !important;
  }
}

.rc-table-body {
  .MuiBox-root {
    font-weight: normal;
    font-size: 14px !important;
    line-height: 22px !important;
    color: $black !important;
  }
}

.subtitle-block {
  .MuiFormControl-root {
    margin-top: -27px;
    margin-bottom: 0;
    margin-left: auto;
    width: 232px;
  }
}

.profile-item {
  .MuiAvatar-root {
    margin: 0 auto 24px;
    width: 119px;
    height: 119px;
  }
}

.tabs-panel {
  width: 200px;
  min-height: unset !important;
  margin: 0 auto;
  border: 1px solid $athens-gray;
  border-radius: 4px;
  background-color: $athens-gray;

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabs-flexContainer {
    width: 100%;
  }

  .MuiTab-root {
    min-width: unset;
    min-height: unset;
    width: 100px;
    padding: 8px 0;
    opacity: 1;
    text-transform: unset;
  }

  .MuiTab-wrapper {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: $black;
  }

  .Mui-selected {
    border-radius: 4px;
    background-color: $white;
  }
}

.MuiSelect-select:focus {
  border-radius: unset !important;
  background-color: transparent !important;
}


.search-input_global {
  .MuiInputBase-root {
    transition: background-color $transition-fast;
  }

  .Mui-focused {
    background-color: $white;
  }
}

.search-input_global-active {
  .MuiInputBase-root {
    background-color: $white;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}