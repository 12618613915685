.header{
  display: flex;
  align-items: center;
  padding: 40px 100px;
  gap: 50px;
  width: 100%;
}

.logout{
  margin-left: auto !important;
}