@import "src/style/scss/colors.scss";


.container{
  position: relative;
}

.card{
  padding: 10px;
  display: grid;
  align-items: center;
  grid-template-columns: 165px 1fr;
  grid-gap: 30px;
  border: 1px solid #EDEFF1;
  border-radius: 8px;
  margin-bottom: 16px;
  
  &__title{
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }

  &__date{
    display: grid;
    align-items: center;
    grid-template-columns: 20px 1fr;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $heather;
    margin-bottom: 6px;
  }
  
  &__desc{
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $dark-gray;
    margin-bottom: 16px;
    word-break: break-all;
  }
  &__content{
    position: relative;
  }
  &__tool_bar{
    right: 0px;
    top:  0px;
    position: absolute;
    display: flex;
    align-items: center;
    &_item{
      padding: 0 3px;
      line-height: 0px;
      font-size: 0px;
    }
  }

  &__img{
    width: 165px;
    height: 165px;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 165px;
    }
  }
}

.hide_content{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba($color: #fff, $alpha: 0.8);
  background: rgba(247, 248, 250, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  svg{
    margin-bottom: 30px;
  }
  p{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 22px;
  }
}
.hide_content_container{
  position: relative;
}