.pill {
  display: inline-block;
  padding: 2px 12px;
  border-radius: 26px;
  border: 1px solid;

  &_orange {
    color: $orange;
    border-color: rgba($orange, .2);

    svg path {
      fill: $orange !important;
    }
  }

  &_blue {
    color: $indigo;
    border-color: rgba($indigo, .2);

    svg path {
      fill: $indigo !important;
    }
  }

  &_gray {
    color: $gray;
    border-color: rgba($dark-gray, .2);

    svg path {
      fill: $mischka !important;
    }
  }

  &_red {
    color: $error;
    border-color: rgba($error, .2);

    svg {
      margin-top: -1px !important;

      path {
        fill: $error !important;
      }
    }
  }

  &_with-icon {
    display: inline-grid;
    grid-auto-flow: column;
    grid-template-columns: 18px 1fr;
    align-items: center;
    grid-gap: 6px;

    // cursor: pointer;
    svg {
      width: 18px;
      height: 18px;
      margin-top: -2px;
    }

    svg path {
      transition: fill $transition-fast;
    }

    // &:hover {
    //   color: $white;

    //   svg path {
    //     fill: white !important;
    //   }
    // }


    //   &.pill_orange {
    //     &:hover {
    //       background-color: $orange;
    //     }
    //   }

    //   &.pill_blue {
    //     &:hover {
    //       background-color: $indigo;
    //     }
    //   }

    //   &.pill_gray {
    //     &:hover {
    //       background-color: $dark-gray;
    //     }
    //   }
  }
}