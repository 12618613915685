.modal-radio-label {
  margin-top: 19px;
}

.modal-radio-icon {
  text-align: center;
  margin-bottom: 11px;
}

.modal-radio-caption {
  text-align: center;

  .small-text {
    color: $black;
    margin-bottom: 1px;
  }

  .sub-text {
    color: $gray;
  }
}
