@import "../../../style/scss/colors";

.upload {
  &-label {
    margin-bottom: 12px;
    span {
      display: inline-block;
      color: $dark-gray;
      background-color: $athens-gray-light;
      border: 1px solid $mischka;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      text-align: center;
      vertical-align: middle;
      font-size: 12px;
      line-height: 22px;
      padding: 0 2px;
      margin-right: 8px;
    }
  }

  &-caption {
    color: $gray;
  }

  &-next {
    margin-top: 8px;
    border-top: 1px solid $athens-gray;
    padding-top: 24px;
  }
}
