.profile {
  &-grid {
    display: grid;
    grid-template: 1fr auto / 1fr 1fr;
    grid-gap: 24px;
  }

  &-item {
    min-width: 326px;
    background-color: $white;
    box-shadow: 0 8px 20px rgba(40, 52, 101, 0.102);
    border-radius: 8px;
    border: 1px solid $athens-gray-light;
    display: flex;
    flex-direction: column;
    padding: 24px;

    &_auto-height {
      margin-bottom: auto;
    }

    &-footer {
      padding-top: 24px;
      margin-top: auto;
      display: flex;
      align-items: center;

      .relative {
        flex-basis: 100%;

        & + .relative {
          margin-left: 24px;
        }
      }

      .button {
        width: 100%;
      }
    }

    .block-title {
      margin: -24px -24px 24px;

      .small-text {
        color: $dark-gray;
      }
    }
  }

  &-user {
    text-align: center;

    &-name {
      margin-bottom: 4px;
    }
  }

  &-tariff {
    &-heading {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 16px;

      &__icon {
        display: flex;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        border-radius: 8px;
        box-shadow: 0 8px 20px rgba(40, 52, 101, 0.102);
        border: 1px solid $athens-gray-light;

        svg {
          width: 24px;
          height: 24px;
          margin: auto;
        }
      }

      &__price {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $gray;

        .title-h3 {
          color: $black;
        }
      }
    }

    &__name {
      margin-bottom: 4px;
    }

    &__description {
      color: $dark-gray;
    }
  }

  &-payment {
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px;
        height: 28px;
        text-align: center;
        margin-right: 12px;
        border: 1px solid $athens-gray-light;
        border-radius: 5px;

        &_mc {
          svg {
            height: 12px;
          }
        }

        &_visa {
          svg {
            height: 10px;
          }
        }
      }

      &__text {
        .main-text {
          text-transform: capitalize;
        }

        .small-text {
          color: $gray;
        }
      }

      .relative {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        margin-left: auto;

        .remove-button {
          width: 32px;
          height: 32px;
          min-width: unset;
        }
      }
    }
  }

  &-history {
    grid-column-start: 1;
    grid-column-end: 3;

    &-full {
      margin-top: 10px;
      margin-left: -11px;
    }
  }

  &-table {
    display: grid;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    grid-column-gap: 10px;
    grid-row-gap: 22px;
    align-items: center;
    grid-template-columns:
            90px
            minmax(170px, 1fr)
            94px
            130px
            1fr;
    grid-auto-rows: 1fr;

    &-head, &-row {
      display: contents;
    }

    &-head {
      color: $gray;
    }

    &-payment-method {
      display: flex;
      align-items: center;
    }

    &-total {
      text-align: right;
    }
  }
}

@media screen and (min-width: 1420px) {
  .profile {
    &-grid {
      grid-template: 1fr auto / 1fr 1fr 1fr;
    }

    &-history {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }
}
