@import "style/scss/colors.scss";
@import "style/scss/mixin/mixin.scss";


.card_user {
  padding: 20px 30px;
  background: #FFFFFF;
  border: 1px solid rgba(14, 31, 65, 0.03);
  box-shadow: 0px 3px 3px rgba(40, 52, 101, 0.03);
  border-radius: 8px;
  position: relative;


  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }

  &__position {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  &__icon {
    display: inline-block;
    line-height: 0;
    cursor: pointer;

    &:hover {
      svg {

        path,
        rect {
          fill: $orange;
        }
      }
    }

    svg {
      width: 16px;
      height: auto;
    }
  }

  &__wrap_media {
    display: flex;
    align-items: flex-end;
    gap: 12px;
  }

  &__location {
    margin-bottom: 15px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__details {
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    gap: 5px;
    border: 1px solid $athens-gray;
    color: $black;

    &:hover {
      text-decoration: none;
      border-color: $orange;
    }

    svg {
      flex-shrink: 0;
    }
  }

  &__trigger_list {
    display: flex;
    align-items: center;
    gap: 10px;

    li {
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
  }

  &__wrap_links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
  }

  &__right_corner {
    position: absolute;
    right: 10px;
    top: 10px;
    align-items: center;
    display: flex;
    gap: 10px;
  }
}