@import "/src/style/scss/colors.scss";

.nav{
  margin-top: 24px;

  &__list{
    &_item{
      position: relative;  
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 10px;
      color: $gray;
      &--active{
        color: $black;
      }
      
      &--passed{
        cursor: pointer;
        color: $black;
      }
      
      &_marker{
        width: 20px;
        display: block;
        height: 20px;
        margin-right: 8px;
        position: relative;
      }
      
    }
  }
}

