$EACHMEDIA: sm,md,lg,xl;

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-mHd: 1450px;
$screen-fHd: 1900px;

$transition-fast: .3s;
$transition-normal: .5s;
$transition-slow: 1s;

$asideWidth: 250px;