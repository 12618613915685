.button,
.button.MuiButton-contained {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-width: unset;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  transition: background-color $transition-normal, background-image $transition-normal;
  color: $black;
  box-shadow: unset !important;
  background-image: none;
  background-color: unset;
  text-transform: none;

  & svg,
  & svg path {
    fill: currentColor;
  }

  & span {
    display: inherit;
    color: currentColor;
  }

  &.button_gradient {
    background-image: $signum-gradient;
    background-size: 100%;
    background-position: center;
    color: $white;
    box-shadow: $orange-btn-shadow !important;

    &:hover {
      background-image: $hover-gradient;
    }

    &:active {
      background-image: $active-gradient;
    }

    &:disabled {
      background-image: $disabled-gradient;
    }

    &.button_large {
      padding: 16px 32px;
    }

    &.button_medium {
      padding: 14px 16px;
    }

    &.button_small {
      padding: 8px 12px;
    }
  }

  &.button_blue {
    background-color: $indigo;
    color: #fff;

    &:hover {
      background: darken($color: $indigo, $amount: 15%);
    }
  }

  &.button_error {
    background-color: $error;
    color: #fff;

    &:hover {
      background: darken($color: $error, $amount: 15%);
    }

    .MuiButton-label {
      display: grid;
      align-items: center;
      grid-template-columns: 20px 1fr;

      svg {
        margin-bottom: 2px;
      }
    }
  }

  &.button_ghost {
    color: $black;
    border: 1px solid $mischka;
    background-color: $white;
    background-image: none;
    box-shadow: none !important;
    transition: none;

    &:hover {
      border: 1px solid $heather;
    }

    &:active {
      color: $white;
      background-color: $cinder;
      border-color: $cinder;
    }

    &:disabled {
      color: $gray;
      background-color: $athens-gray;
      border-color: $athens-gray;
    }
  }

  &.button_borderless {
    color: $black;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: none !important;

    &:hover {
      background-color: $athens-gray-light;
      border-color: $athens-gray-light;
    }

    &:active {
      background-color: $athens-gray;
      border-color: $athens-gray;
    }

    &:disabled {
      color: $gray;
    }
  }

  &.button_danger {
    color: $error;
    border: 1px solid $error;
    background-color: $white;
    background-image: none;
    box-shadow: none !important;
    transition: none;

    &:hover {
      color: $error-hover;
      border-color: $error-hover;
    }

    &:active {
      color: $white;
      background-color: $error;
      border-color: $error;
    }

    &:disabled {
      color: $error;
      border-color: rgba($error, .5);
      background-color: $white;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($white, .5);
      }
    }
  }


  &.button_large {
    padding: 15px 31px;
    font-size: 16px;
    line-height: 26px;
    min-height: 58px;
  }

  &.button_medium {
    padding: 13px 15px;
    font-size: 14px;
    line-height: 22px;
  }

  &.button_small {
    padding: 7px 11px;
    font-size: 14px;
    line-height: 22px;
  }

  &.button_square {
    display: flex;
    justify-content: center;
    align-items: center;

    &.button_large {
      padding: 19px;
      width: 56px;
      height: 56px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &.button_medium {
      padding: 17px;
      width: 48px;
      height: 48px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    &.button_small {
      padding: 12px;
      width: 36px;
      height: 36px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &.button_text {
    color: $black;
    padding: 0px;
    height: auto;
    line-height: 1.2;
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none !important;
    transition: none;
    cursor: pointer;

    &_orange {
      color: $orange;
    }

    &:hover {
      opacity: .7;
    }

    &:disabled {
      color: $gray;
      cursor: not-allowed;
    }
  }


  &.edit-button {
    color: $heather;
  }

  &.remove-button {
    color: $heather;
    width: 20px;
    height: 20px;
    padding: 0;
    background-color: transparent;

    &:hover {
      color: $orange;
    }
  }

  &.notifications {
    padding: 0;
    width: 16px;
    height: 19px;
    margin-right: 27px;

    span {
      width: auto;
    }
  }

  &.user-button {
    background-color: $white;
    color: $black;
    padding: 0;
  }

  &:hover {
    text-decoration: none;
    background-color: unset;
  }

  &:disabled {
    cursor: not-allowed;
  }
}