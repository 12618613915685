@import '../../../style/scss/colors';

.modal {
  :global {
    .MuiBackdrop-root {
      opacity: .6;
    }
  }

  &__outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    padding: 24px;
    pointer-events: none;
  }

  &__inner {
    background-color: $white;
    border: 1px solid $athens-gray;
    box-shadow: 0 5px 41px rgba(15, 16, 23, 0.03);
    border-radius: 8px;
    margin: auto;
    width: 100%;
    max-width: 528px;
    // min-height: 225px;
    max-height: calc(100vh - 48px);
    overflow: auto;
    pointer-events: auto;
    position: relative;

    &--auto {
      min-width: 528px;
      max-width: none;
      width: auto;
    }

    &--middle {
      max-width: 800px;
    }

    &--large {
      max-width: 1080px;
    }
  }

  &__header {
    padding: 16px 24px;
    position: relative;
    border-bottom: 1px solid $athens-gray;
  }

  &__close {
    position: absolute;
    z-index: 10;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    &::before,
    &::after {
      content: '';
      width: 20px;
      height: 2px;
      border-radius: 2px;
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: #000;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &_align {
      &_left {}

      &_center {
        justify-content: center;
        text-align: center;
      }

      &_right {
        justify-content: flex-end;
        text-align: right;
      }
    }
  }

  &__body {
    padding: 16px 24px;
  }

  &__footer {
    padding: 12px 24px 24px;

    &_btn {
      margin-left: 12px;
      margin-right: 12px;
    }

    &--spacer {
      margin-left: -24px;
      margin-right: -24px;
      padding-bottom: 8px;
    }


    &_inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: -12px;
      margin-right: -12px;
    }

    &--center {
      justify-content: center;
    }

  }

  .delete-caption {
    width: 100%;
    max-width: 386px;
    margin: 0 auto;
    color: $dark-gray;
  }
}

.modal-inner-static {
  position: relative;
  margin: -16px -24px 0;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    box-shadow: inset 0 6px 12px rgba(0, 0, 0, 0.05),
      inset 0 -6px 12px rgba(0, 0, 0, 0.05);
    pointer-events: none;
  }
}

.modal-inner-scroll {
  min-height: 250px;
  max-height: 338px;
  overflow-y: auto;
  padding: 0 24px 12px;
}


.btn.btn.btn {
  min-width: 128px;
}