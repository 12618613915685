@import '../../../style/scss/colors.scss';

.wrapper{
  margin-bottom: 32px;
}
.container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2{
    margin-bottom: 0px;
  }
}

.blockSubTitle{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.subTitle{
  color: $dark-gray;
  .main-text {
    color: $dark-gray;

    &__multiple-items {
      display: inline-block;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.addingContent{
  flex-shrink: 0;
  margin-top: -27px;
  
}