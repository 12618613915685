// Mixins & Includes

@import "../variable.scss";

@mixin placeholderColor($input-text-color) {
    &::-webkit-input-placeholder {
        color: $input-text-color;
    }

    &:-moz-placeholder {
        color: $input-text-color;
        opacity: 1;
    }

    &::-moz-placeholder {
        color: $input-text-color;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: $input-text-color;
    }

    &::placeholder {
        color: $input-text-color;
        opacity: 1;
    }

    &:focus:-moz-placeholder {
        color: transparent;
        opacity: 1;
    }

    &:focus::-moz-placeholder {
        color: transparent;
        opacity: 1;
    }

    &:focus::-webkit-input-placeholder {
        color: transparent;
    }

    &:focus:-ms-input-placeholder {
        color: transparent;
    }

    &:focus::placeholder {
        color: transparent;
    }
}

@mixin clearSelect {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

@mixin m($point) {
    @if $point==fHd {
        @media only screen and (min-width: $screen-fHd) {
            @content;
        }
    }
    @else if $point==maxFhd {
        @media only screen and (max-width: ($screen-fHd - 0.1px)) {
            @content;
        }
    }
    @else if $point==mHd {
        @media only screen and (min-width: $screen-mHd) {
            @content;
        }
    }
    @else if $point==maxMhd {
        @media only screen and (max-width: ($screen-mHd - 0.1px)) {
            @content;
        }
    }
    @else if $point==xl {
        @media only screen and (min-width: $screen-xl) {
            @content;
        }
    }
    @else if $point==maxXl {
        @media only screen and (max-width: ($screen-xl - 0.1px)) {
            @content;
        }
    }
    @else if $point==lg {
        @media only screen and (min-width: $screen-lg) {
            @content;
        }
    }
    @else if $point==maxLg {
        @media only screen and (max-width: ($screen-lg - 0.1px)) {
            @content;
        }
    }

    @else if $point==md{
        @media only screen and (min-width: $screen-md) {
            @content;
        }
    }
    @else if $point==maxMd {
        @media only screen and (max-width: ($screen-md - 0.1px)) {
            @content;
        }
    }
    @else if $point==sm{
        @media only screen and (min-width: $screen-sm) {
            @content;
        }
    }
    @else if $point==maxSm {
        @media only screen and (max-width: ($screen-sm - 0.1px)) {
            @content;
        }
    }
    @else {
        @media only screen and (min-width: $point + "px") {
            @content;
        }
    }
}

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@mixin margin-c {
    margin: {
        left: auto;
        right: auto;
    }
}
@mixin pseudo( $content: '',  $pos: absolute, $display: block){
    content: $content;
    display: $display;
    position: $pos;
}

@mixin size($point) {
    width: $point * (100% / 12);
    max-width: $point * (100% / 12);
    flex-basis: ($point * (100% / 12));
}

@mixin size-w($width){
    width: $width;
    max-width: $width;
    flex-basis: $width;
}

@mixin hide{
    display: none;
    visibility: hidden;
}

@mixin show{
    display: block;
    visibility: visible;
}

@mixin show-l{
    display: inline-block;
    visibility: visible;
}
@mixin  absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}