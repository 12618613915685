$M: false;
$MSM: false;
$MMD: false;
$MLG: false;
$MXL: false;

$MB: 'auto',4,8,12,15,16,24,30,32,35,40;
$MBSM: false;
$MBMD: false;
$MBLG: false;
$MBXL: false;

$MT: 'auto',8,24,32,40,50;
$MTSM: false;
$MTMD: false;
$MTLG: false;
$MTXL: false;

$ML: 16,24;
$MLSM: false;
$MLMD: false;
$MLLG: false;
$MLXL: false;


$MR: 6,16,18,24;
$MRSM: false;
$MRMD: false;
$MRLG: false;
$MRXL: false;


$MY: 16;
$MYSM: false;
$MYMD: false;
$MYLG: false;
$MYXL: false;


$MX: false;
$MXSM: false;
$MXMD: false;
$MXLG: false;
$MXXL: false;


body .m {
    @if $M !=false {
        @each $i in $M {
            &--#{$i} {
                @if $i=='auto' {
                    margin: #{$i};
                }

                @else {
                    margin: #{$i}px;
                }
            }
        }
    }

    &-sm {
        @if $MSM !=false {
            @include m(sm) {
                @each $i in $MSM {
                    &--#{$i} {
                        @if $i=='auto' {
                            margin: #{$i};
                        }

                        @else {
                            margin: #{$i}px;
                        }
                    }
                }
            }
        }
    }

    &-md {
        @if $MMD !=false {
            @include m(md) {
                @each $i in $MMD {
                    &--#{$i} {
                        @if $i=='auto' {
                            margin: #{$i};
                        }

                        @else {
                            margin: #{$i}px;
                        }
                    }
                }
            }
        }
    }

    &-lg {
        @if $MLG !=false {
            @include m(lg) {
                @each $i in $MLG {
                    &--#{$i} {
                        @if $i=='auto' {
                            margin: #{$i};
                        }

                        @else {
                            margin: #{$i}px;
                        }
                    }
                }
            }
        }

    }

    &-xl {
        @if $MXL !=false {
            @include m(xl) {
                @each $i in $MXL {
                    &--#{$i} {
                        @if $i=='auto' {
                            margin: #{$i};
                        }

                        @else {
                            margin: #{$i}px;
                        }
                    }
                }
            }
        }
    }


    &-b {
        @if $MB !=false {
            @each $i in $MB {
                &--#{$i} {
                    @if $i=='auto' {
                        margin-bottom: #{$i};
                    }

                    @else {
                        margin-bottom: #{$i}px;
                    }
                }
            }
        }

        &-sm {
            @if $MBSM !=false {
                @include m(sm) {
                    @each $i in $MBSM {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-bottom: #{$i};
                            }

                            @else {
                                margin-bottom: #{$i}px;
                            }
                        }
                    }
                }
            }
        }

        &-md {
            @if $MBMD !=false {
                @include m(md) {
                    @each $i in $MBMD {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-bottom: #{$i};
                            }

                            @else {
                                margin-bottom: #{$i}px;
                            }
                        }
                    }
                }
            }


        }

        &-lg {
            @if $MBLG !=false {
                @include m(lg) {
                    @each $i in $MBLG {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-bottom: #{$i};
                            }

                            @else {
                                margin-bottom: #{$i}px;
                            }
                        }
                    }
                }
            }

        }

        &-xl {
            @if $MBXL !=false {
                @include m(xl) {
                    @each $i in $MBXL {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-bottom: #{$i};
                            }

                            @else {
                                margin-bottom: #{$i}px;
                            }
                        }
                    }
                }
            }


        }
    }

    &-t {
        @if $MT !=false {
            @each $i in $MT {
                &--#{$i} {
                    @if $i=='auto' {
                        margin-top: #{$i};
                    }

                    @else {
                        margin-top: #{$i}px;
                    }
                }
            }
        }

        &-sm {
            @if $MTSM !=false {
                @include m(sm) {
                    @each $i in $MTSM {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-top: #{$i};
                            }

                            @else {
                                margin-top: #{$i}px;
                            }
                        }
                    }
                }
            }
        }

        &-md {
            @if $MTMD !=false {
                @include m(sm) {
                    @each $i in $MTMD {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-top: #{$i};
                            }

                            @else {
                                margin-top: #{$i}px;
                            }
                        }
                    }
                }
            }
        }

        &-lg {
            @if $MTLG !=false {
                @include m(lg) {
                    @each $i in $MTLG {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-top: #{$i};
                            }

                            @else {
                                margin-top: #{$i}px;
                            }
                        }
                    }
                }
            }


        }

        &-xl {
            @if $MTXL !=false {
                @include m(xl) {
                    @each $i in $MTXL {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-top: #{$i};
                            }

                            @else {
                                margin-top: #{$i}px;
                            }
                        }
                    }
                }
            }


        }
    }

    &-r {
        @if $MR !=false {
            @each $i in $MR {
                &--#{$i} {
                    @if $i=='auto' {
                        margin-right: #{$i};
                    }

                    @else {
                        margin-right: #{$i}px;
                    }
                }
            }
        }

        &-sm {
            @if $MRSM !=false {
                @include m(sm) {
                    @each $i in $MRSM {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-right: #{$i};
                            }

                            @else {
                                margin-right: #{$i}px;
                            }
                        }
                    }
                }
            }
        }

        &-md {
            @if $MRMD !=false {
                @include m(md) {
                    @each $i in $MRMD {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-right: #{$i};
                            }

                            @else {
                                margin-right: #{$i}px;
                            }
                        }
                    }
                }
            }
        }

        &-lg {
            @if $MRLG !=false {
                @include m(lg) {
                    @each $i in $MRLG {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-right: #{$i};
                            }

                            @else {
                                margin-right: #{$i}px;
                            }
                        }
                    }
                }
            }


        }

        &-xl {
            @if $MRXL !=false {
                @include m(xl) {
                    @each $i in $MRXL {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-right: #{$i};
                            }

                            @else {
                                margin-right: #{$i}px;
                            }
                        }
                    }
                }
            }


        }
    }

    &-l {
        @if $ML !=false {
            @each $i in $ML {
                &--#{$i} {
                    @if $i=='auto' {
                        margin-left: #{$i};
                    }

                    @else {
                        margin-left: #{$i}px;
                    }
                }
            }
        }

        &-sm {
            @if $MLSM !=false {
                @include m(sm) {
                    @each $i in $MLSM {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-left: #{$i};
                            }

                            @else {
                                margin-left: #{$i}px;
                            }
                        }
                    }
                }
            }
        }

        &-md {
            @if $MLMD !=false {
                @include m(md) {
                    @each $i in $MLMD {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-left: #{$i};
                            }

                            @else {
                                margin-left: #{$i}px;
                            }
                        }
                    }
                }
            }


        }

        &-lg {
            @if $MLLG !=false {
                @include m(lg) {
                    @each $i in $MLLG {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-left: #{$i};
                            }

                            @else {
                                margin-left: #{$i}px;
                            }
                        }
                    }
                }
            }


        }

        &-xl {
            @if $MLXL !=false {
                @include m(xl) {
                    @each $i in $MLXL {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-left: #{$i};
                            }

                            @else {
                                margin-left: #{$i}px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-y {
        @if $MY !=false {
            @each $i in $MY {
                &--#{$i} {
                    @if $i=='auto' {
                        margin-top: #{$i};
                        margin-bottom: #{$i};
                    }

                    @else {
                        margin-top: #{$i}px;
                        margin-bottom: #{$i}px;
                    }
                }

            }
        }

        &-sm {
            @if $MYSM !=false {
                @include m(sm) {
                    @each $i in $MYSM {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-top: #{$i};
                                margin-bottom: #{$i};
                            }

                            @else {
                                margin-top: #{$i};
                                margin-bottom: #{$i};
                            }
                        }
                    }
                }
            }
        }

        &-md {
            @if $MYMD !=false {
                @include m(md) {
                    @each $i in $MYMD {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-top: #{$i};
                                margin-bottom: #{$i};
                            }

                            @else {
                                margin-top: #{$i};
                                margin-bottom: #{$i};
                            }
                        }
                    }
                }
            }
        }

        &-lg {
            @if $MYLG !=false {
                @include m(lg) {
                    @each $i in $MYLG {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-top: #{$i};
                                margin-bottom: #{$i};
                            }

                            @else {
                                margin-top: #{$i};
                                margin-bottom: #{$i};
                            }
                        }
                    }
                }
            }


        }

        &-xl {
            @if $MYXL !=false {
                @include m(xl) {
                    @each $i in $MYXL {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-top: #{$i};
                                margin-bottom: #{$i};
                            }

                            @else {
                                margin-top: #{$i};
                                margin-bottom: #{$i};
                            }
                        }
                    }
                }
            }
        }
    }

    &-x {
        @if $MX !=false {
            @each $i in $MX {
                &--#{$i} {
                     @if $i=='auto' {
                        margin-left: #{$i};
                        margin-right: #{$i};
                    }

                    @else {
                        margin-left: #{$i}px;
                        margin-right: #{$i}px;
                    }
                }

            }
        }

        &-sm {
            @if $MXSM !=false {
                @include m(sm) {
                    @each $i in $MXSM {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-left: #{$i};
                                margin-right: #{$i};
                            }

                            @else {
                                margin-left: #{$i}px;
                                margin-right: #{$i}px;
                            }
                        }
                    }
                }
            }
        }

        &-md {
            @if $MXMD !=false {
                @include m(md) {
                    @each $i in $MXMD {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-left: #{$i};
                                margin-right: #{$i};
                            }

                            @else {
                                margin-left: #{$i}px;
                                margin-right: #{$i}px;
                            }
                        }
                    }
                }
            }
        }

        &-lg {
            @if $MXLG !=false {
                @include m(lg) {
                    @each $i in $MXLG {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-left: #{$i};
                                margin-right: #{$i};
                            }

                            @else {
                                margin-left: #{$i}px;
                                margin-right: #{$i}px;
                            }
                        }
                    }
                }
            }


        }

        &-xl {
            @if $MXXL !=false {
                @include m(xl) {
                    @each $i in $MXXL {
                        &--#{$i} {
                            @if $i=='auto' {
                                margin-left: #{$i};
                                margin-right: #{$i};
                            }

                            @else {
                                margin-left: #{$i}px;
                                margin-right: #{$i}px;
                            }
                        }
                    }
                }
            }
        }
    }
}
