@import "/src/style/scss/colors.scss";

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $signum-gradient;
    border-radius: 10px;
    padding: 20px;
    min-height: 184px;
    min-width: 315px;
    cursor: pointer;
    position: relative;

    &__marker {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 20px;
        top: 20px;
        border-radius: 4px;
        background-color: #fff;

        .card.active & {
            background-image: url('data:image/svg+xml,<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.19484 10.93L16.3648 0.76001L17.7148 2.11001L6.19484 13.63L0.839844 8.27501L2.18984 6.92501L6.19484 10.93Z" fill="%23FF9500"/></svg>');
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__title {
        color: #fff;
        font-size: 26px;
    }

    &__value {
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        margin-top: 8px;
    }
}

.card_new {
    color: #6B6F7B;
    border: 2px dashed currentColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    min-height: 184px;
    min-width: 315px;
    cursor: pointer;
    text-align: center;

    &.active {
        color: $orange;
    }

    &__title {
        color: currentColor;
        font-weight: 500;
        font-size: 16px;
    }

}

.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.footer {
    margin-top: 35px;
    text-align: center;
}