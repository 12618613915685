@import "/src/style/scss/colors.scss";

.section{
  display: flex;
  justify-content: center;
  height: 100%;

  &__item{
    width: 50%;
    padding: 25px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    flex-direction: column;
  }
}

.title{
  font-weight: 400;
  font-size: 43px;
  line-height: 50px;
}

.text{
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  color: $dark-gray;
}


.body{
  margin-top: 60px;

  &__wrap_btn{
    margin-top: 60px;

    span{
      display: inline-block;
      font-weight: 700;
    }
  }

  &__list{
    margin-top: 30px;

    li{
      margin: 6px 0;
      display: flex;
      gap: 20px;
      align-items: center;
      color: #9298A8;
      font-size: 16px;
      line-height: 34px;

      span{
        font-weight: 500;
      }
    }
  }
}


.item{
  min-height: 100px;
  // margin-bottom: 10px;
  max-width: 380px;

  &__text{
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
  }

  &__list{
    margin-top: 20px;
    list-style-type: disc;
    padding-left: 20px;

    li{
      font-size: 20px;
      color: #56637B;
      line-height: 34px;
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }
}
