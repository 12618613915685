@import "/src/style/scss/colors.scss";

.notic{
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 13px 18px;
  border-radius: 8px;
  background: rgba(190, 196, 209, 0.20);
  margin-bottom: 16px;

  p{
    font-size: 14px;
    line-height: 20px;

    strong{
      font-weight: 600;
    }
  }
}